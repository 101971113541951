import { DateTime } from "luxon";
import { SolarData } from "./solar";

export const parseData = (res: SolarData, plotItemLeft: string, plotItemRight?: string) => {
  return res.Items.filter(item => item[plotItemLeft]).map(item => {
    const seconds = Number(item["publish_time"]["N"]);
    const date = DateTime.fromSeconds(seconds);
    const right = plotItemRight ? item[plotItemRight].S ?? item[plotItemRight].N : undefined;
    return {
      date: date.toJSDate(),
      plotItemLeft: Number(item[plotItemLeft].S ?? item[plotItemLeft].N),
      plotItemRight: right ? Number(right) : undefined
    }
  })
}