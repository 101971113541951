import { InputLabel, MenuItem, Select, TextField, Checkbox, CircularProgress } from "@material-ui/core"
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { loadChart } from "../charts";
import { SolarData, SolarService } from "../service/solar";
import { Transition } from '@headlessui/react'
import { parseData } from "../service/util";
import { Chart } from "@amcharts/amcharts4/charts";

const service = new SolarService();

const parseItems = (res: SolarData) => {
  const items: string[] = [];
  res.Items.forEach(i => {
    Object.keys(i).forEach(k => {
      if(!items.includes(k)) {
        items.push(k);
      }
    })
  })
  return items;
}

export const Monthly = (props: {}) => {
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(DateTime.now());
  const [plotItemLeft, setPlotItemLeft] = useState("DAY_ENERGY_Wh");
  const [plotItemRight, setPlotItemRight] = useState("DAY_ENERGY_Wh");
  const [enPlotItemRight, setEnPlotItemRight] = useState(false);
  const [items, setItems] = useState(["PAC_W", "DAY_ENERGY_Wh"]);
  const [data, setData] = useState<SolarData | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    service.getMonthly(date)
      .then(res => {
        if(res) {
          setData(res);
          const foundItems = parseItems(res)
          setItems(foundItems);
        }
      })
      .finally(() => setLoading(false));
  }, [date])

  useEffect(() => {
    let chart: Chart | undefined;
    if(data) {
      chart = loadChart("monthly-chart", parseData(data, plotItemLeft, enPlotItemRight ? plotItemRight : undefined), { type: "column" })
    }
    return () => {
      if(chart) {
        chart.dispose();
      }
    }
  }, [data, plotItemLeft, plotItemRight, enPlotItemRight])

  return <div className="flex flex-col h-full">
    <div className="flex flex-row overflow-auto">
      <div className="p-5">
        <TextField
          id="date"
          label="Select date"
          type="date"
          onChange={(e) => {
            setDate(DateTime.fromJSDate((e.target as HTMLInputElement).valueAsDate as Date))
          }}
          defaultValue={date.toFormat("yyyy-MM-dd")}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <div className="p-5">
        <InputLabel>First plot</InputLabel>
        <Select
          value={plotItemLeft}
          onChange={e => setPlotItemLeft(e.target.value as string)}
        >
          {items.map(i => {
            return <MenuItem value={i}>{i}</MenuItem>
          })}
        </Select>
      </div>
      <div className="p-5">
        <InputLabel>Second plot</InputLabel>
        <Select
          value={plotItemRight}
          onChange={e => setPlotItemRight(e.target.value as string)}
        >
          {items.map(i => {
            return <MenuItem value={i}>{i}</MenuItem>
          })}
        </Select>
      </div>
      <div className="p-5">
        <Checkbox 
          value={enPlotItemRight}
          onChange={e => setEnPlotItemRight(e.target.checked)}
        />
      </div>
    </div>
    <div className="lg:m-5 h-full">
      <div id="monthly-chart" className={`h-full transition duration-500 ${loading ? "opacity-20" : ""}`}></div>
      <Transition
        show={loading}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <aside className="absolute inset-2/4"><CircularProgress  /></aside>
      </Transition>
    </div>
  </div>
}
