import { useEffect } from "react"
import { loadChart } from "../charts";
import { SolarData, SolarService } from "../service/solar";
import { DateTime } from "luxon";
import { Chart } from "@amcharts/amcharts4/charts";

type Props = {}

type SeriesData = {
  date: Date;
  plotItemLeft: number;
}

const Data: SeriesData[] = []
const State = {
  minAge: 0,
  days: 800
}

const parseData = (res: SolarData) => {
  return res.Items.map(item => {
    const seconds = Number(item["publish_time"]["N"]);
    const date = DateTime.fromSeconds(seconds);
    return {
      date: date.toJSDate(),
      plotItemLeft: Number(item["TOTAL_ENERGY_Wh"]["S"])
    }
  })
}

class DataFetcher {
  running = false;
  chart?: Chart;
  service = new SolarService();

  doOne(minAge: number, days: number) {
    this.service.getTotalW(minAge, days)
      .then(res => {
        if(res && res.Items.length > 0) {
          Data.push(...parseData(res));
          if(this.chart) {
            this.chart.data = Data;
          }
          if(this.running) {
            State.minAge += days;
            this.doOne(State.minAge, State.days);
          }
        }
      })
  }

  start() {
    this.running = true;
    this.chart = loadChart("solar-chart", Data, { seriesNameLeft: "Cumulative generation"});
    this.doOne(State.minAge, State.days);
  }

  stop() {
    this.running = false;
  }
}

export const History = (props: Props) => {

  useEffect(() => {
    const fetcher = new DataFetcher();
    fetcher.start();
    return () => fetcher.stop();
  }, [])

  return <div className="flex flex-col flex-grow">
    <div
      className="flex-grow p-5"
      id="solar-chart"
    ></div>
  </div>
}
