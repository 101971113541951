import ky from "ky";
import { DateTime } from "luxon";
import { BaseService, DynamoItem } from "./base";

export type SolarData = {
  Items: DynamoItem[];
}

export class SolarService extends BaseService {

  constructor() {
		super(ky.extend({
			prefixUrl: process.env.REACT_APP_SOLAR_API ?? "https://qlx4c2jri5.execute-api.ap-southeast-2.amazonaws.com/prod/"
		}));
	}

	async getTotalW(minAge: number, days: number) {
		return await this.get<SolarData>("total-w", { minAge, days });
	}

	async getDaily(date: DateTime) {
		return await this.get<SolarData>("daily", { timestamp: date.toFormat("yyyy/MM/dd") });
	}

	async getMonthly(date: DateTime) {
		const startOfMonth = date.startOf("month");
		const endOfMonth = date.endOf("month");
		const now = DateTime.now()
		const age = now.diff(endOfMonth, "days").days;
		let days = endOfMonth.diff(startOfMonth, "days").days;
		if(now < endOfMonth) {
			days = now.diff(startOfMonth, "days").days - 1;
		}
		return await this.get<SolarData>("total-w", { minAge: Math.floor(age >= 0 ? age : 0), days: Math.ceil(days) });
	}
}
